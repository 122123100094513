import React, { useEffect, useState } from "react";
import { useFileState } from "../../providers/FileStateProvider";
import './UploadStatus.css'
import "../NextPrevButton.css"
import { LinearProgress } from '@mui/material'
import { UploaderInstanceStatusResponse } from "../../../../sharedTypes/bucketUploadTypes";
import { UploadStatus as UploadStatusEnum } from "../../lib/consts";
import { auth0Axios } from "../../auth/auth0Axios";
import { TailSpin } from 'react-loader-spinner'


const ATTACHMENTS = 'ATTACHMENTS'

const LoadingSpinner = () => {
    return <TailSpin
        visible={true}
        height="12"
        width="12"
        color="var(--text-primary)"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
    />
};


/**
 * Todo make this into a component instead
 */
const UploadStatusMessage = ({ statusAndResults }: { statusAndResults: UploaderInstanceStatusResponse }) => {
    const { status, viewerUrls, orderUrl } = statusAndResults

    switch (status) {
        case UploadStatusEnum.PROCESSING:
            return (
                <React.Fragment>
                    <p className="upload-footer-header">Uploads Complete</p>
                    <p>
                        Images are being processed.<br />
                        You may safely close your browser window and processing will continue.
                    </p>
                    <div className='upload-footer-loading-spinner'>
                        <LoadingSpinner />
                    </div>

                </React.Fragment>
            );
        case UploadStatusEnum.COMPLETE:
            return (
                <React.Fragment>
                    <p className="upload-footer-header">Uploads Complete</p>
                    {viewerUrls && orderUrl ?
                        <React.Fragment>
                            <p><a className="upload-footer-link" href={`${orderUrl}`}>Link to order on Yunu</a></p>
                            <p>{`View timepoints in Yunu Viewer:`}</p>
                            {viewerUrls.map(({ viewerUrl, trialResponseAssessmentCriteriaName }) => {
                                return <p key={viewerUrl}><a className="upload-footer-link" href={`${viewerUrl}`}>{trialResponseAssessmentCriteriaName}</a></p>
                            })}
                        </React.Fragment>
                        : null}
                </React.Fragment>
            );
        case UploadStatusEnum.FAILED:
            return (
                <React.Fragment>
                    <p className="upload-footer-header">Processing Failed</p>
                    <p>
                        Uploaded images failed to process, please retry.
                    </p>
                </React.Fragment>
            );
    }

    return null;
}

const UploadStatus = () => {
    const { status, studyMetadata, attachments, files, failedUploads, api, uploaderInstanceId } = useFileState()

    const { isUploading } = status

    const [statusAndResults, setStatusAndResults] = useState<UploaderInstanceStatusResponse>({ status: UploadStatusEnum.INITIALIZED, viewerUrls: [] })

    useEffect(() => {
        const pollForStatus = async () => {
            const result = await auth0Axios.get(`/api/uploader/instance/upload-status/${uploaderInstanceId}`)

            const uploadStatus = result.data as UploaderInstanceStatusResponse;

            const { status } = uploadStatus

            if (status !== statusAndResults.status) {
                // Changed, update state
                setStatusAndResults(uploadStatus)

                // Note we can return, a new poll function will be
                // created with the new comparison value by this enclosing useEffect hook.
                return;
            }

            // Keep polling if not complete or failed:
            if (![UploadStatusEnum.COMPLETE, UploadStatusEnum.FAILED].includes(status)) {
                setTimeout(pollForStatus, 2000);
            }
        }


        if (uploaderInstanceId && ![UploadStatusEnum.COMPLETE, UploadStatusEnum.FAILED].includes(statusAndResults.status)) {
            // Fetch API and update statusAndResults
            pollForStatus()
        }


    }, [uploaderInstanceId, statusAndResults])

    const dicomStatusLines = studyMetadata.map(study => {
        const { StudyInstanceUID } = study
        const uploadCount = status.fileGroupUploadCounts[StudyInstanceUID] || 0
        const fileCount = files[StudyInstanceUID].length

        let progress: number

        const complete = uploadCount === fileCount

        if (uploadCount === 0) {
            progress = 0
        } else if (uploadCount / fileCount < 0.01) {
            // For better UX, if we have a single upload completed, show 1% progress
            // Even if not factually accurate.
            progress = 1
        } else if (complete) {
            progress = 100
        } else {
            progress = Math.floor(100 * (uploadCount / fileCount))
        }

        return (
            <div className="upload-status-item" key={StudyInstanceUID}>
                <p className="upload-status-name">{study.header.StudyDescription}</p>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                        backgroundColor: 'var(--loading-white)',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: 'var(--loading-blue)'
                        }
                    }}
                />
                <div className="upload-status-progres-container">
                    <p className="upload-status-progress">{`${progress}% Complete`}</p>
                    <div style={{ width: '12px', height: '12px' }}>
                        {complete ? null : <LoadingSpinner />}
                    </div>
                </div>
            </div>
        )
    })

    const attachmentStatus = () => {
        if (attachments.length === 0) {
            return null
        }

        const uploadCount = status.fileGroupUploadCounts[ATTACHMENTS] || 0
        const fileCount = attachments.length

        let progress: number

        if (uploadCount === fileCount) {
            progress = 100
        } else {
            progress = Math.floor(uploadCount / fileCount)
        }

        return (
            <div className="upload-status-item">
                <p className="upload-status-name">Attachments</p>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                        backgroundColor: 'var(--loading-white)',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: 'var(--loading-blue)'
                        }
                    }}
                />
                <p className="upload-status-progress">{`${progress}% Complete`}</p>
            </div>
        )
    }

    let footer;

    if (isUploading) {
        footer = (
            <button className="cancel-upload-button" onClick={() => api.cancelUpload()}>Cancel Upload</button>
        )
    } else if (failedUploads.length) {
        footer = (
            <div>
                <p className="upload-footer-failed-header">
                    All uploads did not complete successfully due to network errors.
                </p>
                <p>
                    Please retry.
                </p>
                <button className="next-prev-button" onClick={() => api.retryFailedUploads(failedUploads)}>Retry</button>
            </div>
        )
    } else {
        footer = (
            <div>
                <UploadStatusMessage statusAndResults={statusAndResults} />
                <button className="next-prev-button" onClick={() => api.resetState()}>Upload more</button>
            </div>
        )
    }

    return (
        <div className="upload-status">
            {dicomStatusLines}
            {attachmentStatus()}
            {footer}
        </div>
    );
}


export default UploadStatus