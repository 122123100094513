import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SiteUploader from './components/SiteUploader';
import GuestUploader from './components/GuestUploader';
import GuestUploaderManager from './components/Admin/GuestUploaderManager';
import { AuthenticationGuard } from './auth/AuthenticationGuard';
import { withFileStateProvider } from './providers/FileStateProvider';
import ApiKeyManager from './components/Admin/ApiKeyManager';
import Auth0Logout from './components/Auth0Logout';

function App() {
  const GuestUploaderWithFileStateProvider = withFileStateProvider(GuestUploader)

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthenticationGuard component={withFileStateProvider(SiteUploader)} />
        }
      />
      <Route
        path="/:trial_id"
        element={<AuthenticationGuard component={withFileStateProvider(SiteUploader)} />}
      />
      <Route
        path="/:trial_id/:patient_id"
        element={<AuthenticationGuard component={withFileStateProvider(SiteUploader)} />}
      />
      <Route
        path="/:trial_id/:patient_id/:order_id"
        element={<AuthenticationGuard component={withFileStateProvider(SiteUploader)} />}
      />
      <Route
        path="/guest-uploader-manager"
        element={<AuthenticationGuard component={GuestUploaderManager} />}
      />
      <Route
        path="/manage-api-keys"
        element={<AuthenticationGuard component={ApiKeyManager} />}
      />
      <Route
        path="/logout"
        element={<AuthenticationGuard component={Auth0Logout} />}
      />
      <Route
        path="/guest/:id"
        element={<GuestUploaderWithFileStateProvider />}
      />
    </Routes>
  );
}

export default App;
