import { auth0Axios } from '../../auth/auth0Axios';
import { GuestUploader as GuestUploaderType } from '../../../../sharedTypes/bucketUploadTypes';
import { TrialResponse, SitePatientsForPatientResponse, PatientForMrnResponse, DeidentifiedPatientResponse, OrderResponse } from '../../../../sharedTypes/wmsQueryTypes'
import { UploadInput } from '../../providers/FileStateProvider';

const searchForPatientByMrn = async (
  uploadInput: UploadInput,
  mrn: string
): Promise<PatientForMrnResponse | undefined> => {
  const { guestUploader } = uploadInput

  if (guestUploader && guestUploader?.temporary) {
    return fetchPatientByMrnGuest(mrn, guestUploader.guestUploaderId)
  }

  return fetchPatientByMrnLoggedIn(mrn)
}

const searchForDeidentifiedPatient = async (uploadInput: UploadInput, identifiedPatientId: number): Promise<DeidentifiedPatientResponse | undefined> => {
  const { guestUploader } = uploadInput

  if (guestUploader && guestUploader?.temporary) {
    return fetchDeidentifiedPatientGuest(identifiedPatientId, guestUploader.guestUploaderId)
  }

  return fetchDeidentifiedPatientLoggedIn(identifiedPatientId)
}

const fetchGuestUploader = (guestUploaderId: string): Promise<{ guestUploader: GuestUploaderType, helpDeskEmailAddress?: string } | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/guest-uploader/${guestUploaderId}`).then(result => {
      const guestUploader = result.data

      resolve(guestUploader)
    }).catch((error) => {
      resolve(undefined)
    })
  })
}

const fetchTrialLoggedIn = (trialId: string): Promise<TrialResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/trial/${trialId}`).then(result => {
      const trial: TrialResponse = result.data

      resolve(trial)
    }).catch((error) => {
      resolve(undefined)
    })
  })
}

const fetchTrialGuest = (trialId: string, guestUploaderId: string): Promise<TrialResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/trial/${trialId}/guest/${guestUploaderId}`).then(result => {
      const trial: TrialResponse = result.data

      resolve(trial)
    }).catch((error) => {
      resolve(undefined)
    })
  })
}

const fetchPatientLoggedIn = (patientId: string): Promise<SitePatientsForPatientResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/patient/${patientId}`).then(result => {
      const patient: SitePatientsForPatientResponse = result.data

      resolve(patient)
    }).catch((error) => {
      resolve(undefined)
    })
  })
}

const fetchPatientGuest = (patientId: string, guestUploaderId: string): Promise<SitePatientsForPatientResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/patient/${patientId}/guest/${guestUploaderId}`).then(result => {
      const patient: SitePatientsForPatientResponse = result.data

      resolve(patient)
    }).catch((error) => {
      resolve(undefined)
    })
  })
}

const fetchPatientByMrnLoggedIn = (mrn: string): Promise<PatientForMrnResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/patient-by-mrn/${mrn}`).then(result => {
      const patient: PatientForMrnResponse = result.data

      resolve(patient)
    }).catch((error) => {
      resolve(undefined)
    })
  })
}

const fetchPatientByMrnGuest = (mrn: string, guestUploaderId: string): Promise<PatientForMrnResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/patient-by-mrn/${mrn}/guest/${guestUploaderId}`).then(result => {
      const patient: PatientForMrnResponse = result.data

      resolve(patient)
    }).catch((error) => {
      resolve(undefined)
    })
  })
}

const fetchDeidentifiedPatientLoggedIn = (identifiedPatientId: number): Promise<DeidentifiedPatientResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/deidentified-patient/${identifiedPatientId}`).then(result => {
      const patient: DeidentifiedPatientResponse = result.data

      resolve(patient)
    }).catch((error) => {
      resolve(undefined)
    })
  })
}

const fetchDeidentifiedPatientGuest = (identifiedPatientId: number, guestUploaderId: string): Promise<DeidentifiedPatientResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/deidentified-patient/${identifiedPatientId}/guest/${guestUploaderId}`).then(result => {
      const patient: DeidentifiedPatientResponse = result.data

      resolve(patient)
    }).catch((error) => {
      resolve(undefined)
    })
  })
}

const fetchOrderForTrialPatientLoggedIn = (trialId: string, patientId: string, orderId: string): Promise<OrderResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/trial/${trialId}/patient/${patientId}/order/${orderId}`).then((result) => {
      const orderResponse: OrderResponse = result.data;

      resolve(orderResponse)
    }).catch((error) => {
      resolve(undefined)
    })
  })
}

const fetchOrderForTrialPatientGuest = (trialId: string, patientId: string, orderId: string, guestUploaderId: string): Promise<OrderResponse | undefined> => {
  return new Promise((resolve) => {
    auth0Axios.get(`/api/wms/trial/${trialId}/patient/${patientId}/order/${orderId}/guest/${guestUploaderId}`).then((result) => {
      const orderResponse: OrderResponse = result.data;

      resolve(orderResponse)
    }).catch((error) => {
      resolve(undefined)
    })
  })
}

export {
  fetchGuestUploader,
  fetchTrialLoggedIn,
  fetchTrialGuest,
  fetchPatientLoggedIn,
  fetchPatientGuest,
  fetchOrderForTrialPatientLoggedIn,
  fetchOrderForTrialPatientGuest,
  searchForPatientByMrn,
  searchForDeidentifiedPatient
}