import React from 'react'
import InputComponent from '../Anonymization/input/InputComponent'

type PatientInfoRowProps = {
    displayName: string,
    dicomKeyword: string,
    defaultIdentifiedValue: string,
    identifiedValueFixed: boolean,
    setNewIdentifiedValue: (value: string) => void
    defaultDeidentifiedValue: string,
    deidentifiedValueFixed: boolean,
    setNewDeidentifiedValue: (value: string) => void
}

const DeidentifiedPatientInfoRow = ({
    displayName,
    dicomKeyword,
    defaultIdentifiedValue,
    identifiedValueFixed,
    setNewIdentifiedValue,
    defaultDeidentifiedValue,
    deidentifiedValueFixed,
    setNewDeidentifiedValue
}: PatientInfoRowProps) => {
    return (
        <tr>
            <td className='patient-info-table-cell'>{displayName}</td>
            <InputComponent
                dicomKeyword={dicomKeyword}
                setNewDicomTag={setNewIdentifiedValue}
                defaultValue={defaultIdentifiedValue}
                fixed={identifiedValueFixed}
            />
            <InputComponent
                dicomKeyword={dicomKeyword}
                setNewDicomTag={setNewDeidentifiedValue}
                defaultValue={defaultDeidentifiedValue}
                fixed={deidentifiedValueFixed}
            />
        </tr>
    )
}

export default DeidentifiedPatientInfoRow