import React, { useEffect, useState } from 'react';
import { UploadLevel, useFileState } from '../../providers/FileStateProvider';
import './SelectStudies.css';
import '../NextPrevButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { formatDate } from '../../lib/format';
import { dicomDateIncludingTimeZone } from '../../lib/dicomDateIncludingTimezone';

type StudyEntry = {
  StudyInstanceUID: string;
  PatientID: string;
  StudyDate: string;
  Modality: string;
  StudyDescription: string;
  fileCount: number;
};

const getNewOrderScanMessage = (newOrderScans: string[]): string => {
  if (newOrderScans.length === 1) {
    return `Study ${newOrderScans} is for a scan date not present on the order. If you proceed with upload these scan dates will be added to the order.`
  }

  return `Studies ${newOrderScans.join(', ')} are for scan dates not present on the order. If you proceed with upload these scan dates will be added to the order.`
}

const getUnsupportedFilesText = (unsupportedFileNames: string[]): string => {
  const numUnsupportedFiles = unsupportedFileNames.length;

  if (numUnsupportedFiles === 1) {
    return `${unsupportedFileNames[0]}`;
  } else if (numUnsupportedFiles === 2) {
    return `${unsupportedFileNames[0]}, ${unsupportedFileNames[1]}`;
  }

  return `${unsupportedFileNames[0]}, ${unsupportedFileNames[1]} and ${numUnsupportedFiles - 2
    } more files`;
};

const dicomDateToJSDate = (dicomDate: string) => {
  const jsDate = new Date();

  const year = Number(dicomDate.slice(0, 4));
  const month = Number(dicomDate.slice(4, 6));
  const date = Number(dicomDate.slice(6, 8));

  jsDate.setFullYear(year);
  jsDate.setMonth(month - 1); // Note: JS months are zero indexed.
  jsDate.setDate(date);

  return jsDate;
};

const SelectStudies = () => {
  const [newOrderScanStudies, setNewOrderScanStudies] =
    useState<string[]>([]);
  const { api, studyMetadata, unsupportedFileNames, uploadInput } =
    useFileState();
  let unsupportedText = unsupportedFileNames.length
    ? getUnsupportedFilesText(unsupportedFileNames)
    : undefined;

  const [studyEntries, setStudyEntries] = useState<StudyEntry[]>([]);
  const [differentMrns, setDiferentMrns] = useState<boolean>(false);

  useEffect(() => {
    if (
      uploadInput.uploadLevel === UploadLevel.ORDER &&
      uploadInput.order?.dicomFormattedScanDates
    ) {
      // If this is an order level upload, warn for studies outside the allowed date range.
      const orderScanDates = uploadInput.order.dicomFormattedScanDates;

      const studiesNotOnAnOrderScanDate: string[] = [];

      studyMetadata.forEach((study) => {
        const { StudyDate, TimezoneOffsetFromUTC, StudyTime } = study.header;

        const studyDate = dicomDateIncludingTimeZone(StudyDate, {
          TimezoneOffsetFromUTC: TimezoneOffsetFromUTC,
          dicomTime: StudyTime
        });

        if (!orderScanDates.includes(studyDate)) {
          studiesNotOnAnOrderScanDate.push(study.header.StudyDescription);
        }
      });

      setNewOrderScanStudies(studiesNotOnAnOrderScanDate);
    }

    const updatedStudyEntries: StudyEntry[] = studyMetadata.map((study) => {
      const { header, fileCount, StudyInstanceUID } = study;

      return {
        PatientID: header.PatientID,
        StudyDate: formatDate(header.StudyDate),
        Modality: header.Modality,
        StudyDescription: header.StudyDescription,
        fileCount: fileCount,
        StudyInstanceUID,
      };
    });

    let newDifferentMrnsValue = false;

    if (studyMetadata.length) {
      newDifferentMrnsValue = studyMetadata.some(
        (se) => se.header.PatientID !== studyMetadata[0].header.PatientID
      )
    }

    setStudyEntries(updatedStudyEntries);
    setDiferentMrns(newDifferentMrnsValue);
  }, [studyMetadata]);

  const nextButtonDisabled = differentMrns || !studyEntries.length;

  const nextButtonClass = nextButtonDisabled
    ? 'next-prev-button next-prev-button_disabled'
    : 'next-prev-button';

  const tableRows = studyEntries.map((studyEntry, index) => {
    return (
      <tr key={index}>
        <td>{studyEntry.PatientID}</td>
        <td>{studyEntry.StudyDate}</td>
        <td>{studyEntry.Modality}</td>
        <td>{studyEntry.StudyDescription}</td>
        <td>{studyEntry.fileCount}</td>
        <td
          className="select-studies-delete"
          onClick={() => api.removeStudies([studyEntry.StudyInstanceUID])}
        >
          <FontAwesomeIcon icon={faCircleXmark} />
        </td>
      </tr>
    );
  });

  if (!studyEntries.length) {
    return null;
  }

  return (
    <div className="select-studies">
      {unsupportedText ? (
        <p className="unsupported-files">
          {`Some selected files are not supported: ${unsupportedText}.`}
          <br />
          These files will be removed fom the upload.
        </p>
      ) : null}
      <div className="select-studies-table-container">
        <table className="select-studies-table">
          <tbody>
            <tr className="select-studies-table-header">
              <th>Patient ID</th>
              <th>Exam Date</th>
              <th>Modality</th>
              <th>Study Description</th>
              <th>Images</th>
              <th></th>
            </tr>
            {tableRows}
          </tbody>
        </table>
      </div>
      {differentMrns ? (
        <p className="select-studies-warning">
          Please upload data for a single Patient ID at a time
        </p>
      ) : null}
      <button
        className={nextButtonClass}
        disabled={nextButtonDisabled}
        onClick={() => api.selectStudies()}
      >
        Next
      </button>
      {newOrderScanStudies.length ? (
        <p className="order-studies-warning">
          {getNewOrderScanMessage(newOrderScanStudies)}
        </p>
      ) : null}
    </div>
  );
};

export default SelectStudies;
