type formatDicomDateToWMSDateOptions = {
    /**
     * Format: HHMMSS (ignore fractional time)
     */
    dicomTime?: string; //
    /**
     * Tag: (0008,0201)
     * Format: &HHMM
     * -- where: & can be +/i, HH is hour offset and MM is minutes.
     */
    TimezoneOffsetFromUTC?: string;
};

const getTime = (dicomTime?: string): string => {
    if (!dicomTime) {
        return `00:00:00`;
    }

    const hours = dicomTime.slice(0, 2);
    const minutes = dicomTime.slice(2, 4);
    const seconds = dicomTime.slice(4, 6);

    return `${hours}:${minutes}:${seconds}`;
};

/**
 * If we have the type 3 (type 1 on some IODs) field for offset, use it, otherwise the DICOM
 * is captured in UTC.
 */
const getTimeZoneOffset = (TimezoneOffsetFromUTC?: string): string => {
    if (!TimezoneOffsetFromUTC) {
        return `+00:00`;
    }

    // &HH
    const hoursOffsetWithSign = TimezoneOffsetFromUTC.slice(0, 3);
    // MM
    const minutesOffset = TimezoneOffsetFromUTC.slice(3, 5);

    return `${hoursOffsetWithSign}:${minutesOffset}`;
};

/**
 * Use timezone information to get the UTC date in dicom format.
 */
const dicomDateIncludingTimeZone = (
    dicomDate: string, //YYYYMMDD
    options: formatDicomDateToWMSDateOptions = {}
): string => {
    const year = dicomDate.slice(0, 4);
    const month = dicomDate.slice(4, 6);
    const day = dicomDate.slice(6, 8);

    const time = getTime(options.dicomTime);
    const timezoneOffset = getTimeZoneOffset(options.TimezoneOffsetFromUTC);

    const dateString = `${month} ${day}, ${year} ${time} UTC${timezoneOffset}`;

    const jsDate = new Date(dateString);

    const utcDate = new Date(jsDate.toUTCString());

    const utcYear = utcDate.getFullYear();
    const utcMonth = utcDate.getMonth() + 1; // Months return zero-indexed.
    const utcDay = utcDate.getDate();

    return `${utcYear}${utcMonth}${utcDay}`;
};

export { dicomDateIncludingTimeZone };