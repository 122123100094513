import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  DicomTagOverridesPerStudy,
  useFileState,
} from '../providers/FileStateProvider';
import { StateCode } from '../providers/FileStateProvider';
import { GuestUploader as GuestUploaderType } from '../../../sharedTypes/bucketUploadTypes';
import GuestUploaderSelectData from './GuestUploader/GuestUploaderSelectData';
import AnonymizationAndAttachments from './Shared/AnonymizationAndAttachments';
import UploadStatus from './Shared/UploadStatus';
import { UserDefinedDeidentifyListPerStudy } from '../lib/deidentification/types';
import PageWrapper from './PageWrapper';
import {
  fetchGuestUploader,
  fetchTrialGuest,
  fetchPatientGuest,
  fetchOrderForTrialPatientGuest,
} from '../lib/api/wms';
import { DeidentificationProtocol, TagPatchConfig } from '../../../sharedTypes/deidentifyTypes';
import { OrderResponse, SitePatientInfo, SitePatientsForPatientResponse, TrialResponse } from '../../../sharedTypes/wmsQueryTypes';

// Extend react type interfaces to allow folder selection.
declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes for folder upload to work.
    directory?: string;
    webkitdirectory?: string;
  }
}

function GuestUploader() {
  const { id } = useParams();
  const {
    api,
    files,
    attachments,
    studyMetadata,
    guestSenderInfo,
    state,
    mandatoryDeidentifyList,
    uploadInput,
  } = useFileState();
  const [urlSearchParams] = useSearchParams();

  const [guestUploader, setGuestUploader] = useState<GuestUploaderType | null>(
    null
  );
  const [invalidMessage, setInvalidMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    // Reset the state on page load
    api.resetState();

    const initialize = async (id: string): Promise<void> => {
      const result = await fetchGuestUploader(id);

      if (!result) {
        setInvalidMessage('Invalid guest uploader.');
        return;
      }

      const { guestUploader, helpDeskEmailAddress } = result

      if (guestUploader.disabled) {
        const helpDeskEmailAddressDisplayValue = helpDeskEmailAddress !== undefined ? helpDeskEmailAddress : "support@yunu.io";


        // Note: We can safely just exit here. This guest uploader will be blocked by the backend if one tries to create.
        // An instance manually via API anyway.
        setInvalidMessage(`Uploader is not active. Please contact ${helpDeskEmailAddressDisplayValue} for assistance.`);

        return;
      }

      const { organization_id, id: guestUploaderId, temporary } = guestUploader;

      /**
       * Note: We do this here to prevent confusion between null and undefined later.
       * DB entries come back as null if not populated.
       */
      const trial_id: string | undefined = guestUploader.trial_id || undefined;
      const patient_id: string | undefined =
        guestUploader.patient_id || undefined;
      const order_id: string | undefined = guestUploader.order_id || undefined;

      let trial: TrialResponse | undefined = undefined;
      let patient: SitePatientsForPatientResponse | undefined = undefined;
      let order: OrderResponse | undefined = undefined;

      if (
        trial_id !== undefined &&
        patient_id !== undefined &&
        order_id !== undefined
      ) {
        order = await fetchOrderForTrialPatientGuest(
          trial_id,
          patient_id,
          order_id,
          guestUploaderId
        );

        if (!order) {
          // Order does not exist for patient.
          setInvalidMessage('Order for patient in trial does not exist.');
          return;
        }
      }

      if (trial_id !== undefined) {
        trial = await fetchTrialGuest(trial_id, guestUploaderId);

        if (!trial) {
          setInvalidMessage('Trial does not exist.');
          return;
        }
      }

      if (patient_id !== undefined) {
        patient = await fetchPatientGuest(patient_id, guestUploaderId);

        if (!patient) {
          setInvalidMessage('Patient does not exist.');
          return;
        }
      }

      /**
       * Custom deidentification protocol for the guest uploader.
       */
      const guestUploaderProtocol = guestUploader.default_tag_input?.protocol;

      const site_id = guestUploader.site_id !== null ? guestUploader.site_id : undefined

      let deidentifying: boolean;
      let trialAnonymizationProtocol: DeidentificationProtocol | undefined = undefined;
      let redactBurnedInPHI: boolean = false

      if (trial) {
        deidentifying = trial.anonymizationProtocol.deidentifying || !!guestUploaderProtocol;
        redactBurnedInPHI = trial.anonymizationProtocol.redactBurnedInPHI;
        trialAnonymizationProtocol = trial.anonymizationProtocol.deidentificationProtocol
      } else {
        deidentifying = !!guestUploaderProtocol;
      }

      // Set state
      api.setUploadInput({
        guestUploader: {
          guestUploaderId: guestUploader.id,
          temporary,
          site_id
        },
        trial_id,
        patient_id,
        order_id,
        trial,
        patient,
        order,
        deidentifying,
      });

      api.setMandatoryDeidentifyList(
        trialAnonymizationProtocol,
        guestUploaderProtocol
      );
      api.setMandatoryRedactBurnedInPHI(redactBurnedInPHI);

      setGuestUploader(guestUploader);
    };

    if (id) {
      initialize(id);
    }

    // We really only want this to happen once.
    // eslint-disable-next-line
  }, [id, urlSearchParams]);

  if (invalidMessage) {
    return (
      <PageWrapper>
        <p>{invalidMessage}</p>
      </PageWrapper>
    );
  }

  if (guestUploader === null) {
    return (
      <PageWrapper>
        <p>Loading</p>
      </PageWrapper>
    );
  }

  const isNonTemporaryGuestUploader = uploadInput.guestUploader?.temporary !== undefined ? !uploadInput.guestUploader?.temporary : false;

  if (state === StateCode.SELECT_DATA) {
    return (
      <PageWrapper
        trialId={uploadInput.trial_id}
        trial={uploadInput.trial}
        patient={uploadInput.patient}
        order={uploadInput.order}
        isNonTemporaryGuestUploader={isNonTemporaryGuestUploader}
      >
        <GuestUploaderSelectData guestUploader={guestUploader} />
      </PageWrapper>
    );
  }

  if (state === StateCode.DEID_AND_ADD_ATTACHMENTS) {
    const onUpload = (
      newDicomTagOverridesPerStudy: DicomTagOverridesPerStudy,
      newUserDefinedDeidentifyListPerStudy: UserDefinedDeidentifyListPerStudy,
      patientInformation: SitePatientInfo | undefined,
      deidentifiedPatientTagPatchConfig: TagPatchConfig | undefined,
      redactBurnedInPHI: boolean,
      site_id?: string
    ) =>
      api.upload({
        files,
        attachments,
        uploadInput,
        site_id,
        studyMetadata,
        patientInformation,
        deidentification: {
          deidentifiedPatientTagPatchConfig,
          mandatoryDeidentifyList,
          userDefinedDeidentifyListPerStudy:
            newUserDefinedDeidentifyListPerStudy,
          dicomTagOverridesPerStudy: newDicomTagOverridesPerStudy,
          redactBurnedInPHI,
        },
        guestSenderInfo,
      });

    return (
      <PageWrapper
        trialId={uploadInput.trial_id}
        trial={uploadInput.trial}
        patient={uploadInput.patient}
        order={uploadInput.order}
        isNonTemporaryGuestUploader={isNonTemporaryGuestUploader}
      >
        <AnonymizationAndAttachments
          onUpload={onUpload}
          canAddAttachments={uploadInput.trial_id !== undefined}
        />
      </PageWrapper>
    );
  }

  if (state === StateCode.UPLOAD_STATUS) {
    return (
      <PageWrapper
        trialId={uploadInput.trial_id}
        trial={uploadInput.trial}
        patient={uploadInput.patient}
        order={uploadInput.order}
        isNonTemporaryGuestUploader={isNonTemporaryGuestUploader}
      >
        <UploadStatus />
      </PageWrapper>
    );
  }

  return <div className="guest-uploader" />;
}

export default GuestUploader;
