import React, { useState } from 'react'
import { MobileDatePicker,  } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles"
import dayjs from 'dayjs';

import "./DateInput.css"

type DateInputProps = {
    defaultValue?: string
    setNewDicomTag: (value: string) => void
}

/**
 * Note this is a really simple component which was easy to slap in whilst we aren't focusing too heavily on polish.
 * We should eventually use Yunu-UI when its a library, it can just replace this component.
 */
const DateInput = ({ defaultValue, setNewDicomTag }: DateInputProps) => {
    const onDateChange = (evt: any) => {
        let date = ''

        if (evt && evt.isValid()) {
            date = evt.toDate().toISOString().split("T")[0].replaceAll("-","")

            setNewDicomTag(date)
        }
    }



    let formattedDefaultValue: string | undefined = undefined
    if (defaultValue !== undefined) {
        formattedDefaultValue =  `${defaultValue.slice(0,4)}-${defaultValue.slice(4,6)}-${defaultValue.slice(6,8)}`
    }

    const theme = createTheme({
        palette: {
            text: {
                primary: "#ffffff",
                secondary: "#91b9cd",
            }
        },
        components: {
            MuiIconButton: {
                styleOverrides: {
                    sizeMedium: {
                        color: "var(--text-primary)"
                    },
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        width: "15px"
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        color: "var(--text-primary)",
                    },
                    input: {
                        padding: "0px 5px",     
                        "&::placeholder": {
                            color: "var(--text-green)",
                            opacity: 1,
                         },
                    },
                    notchedOutline: {
                        border: "none"
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        height: "24px",
                    },
                    input: {
                        height: "24px",
                        padding: "0px 5px"
                        
                    },

                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: "var(--text-primary)"
                    }
                }
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        color: "var(--text-primary)",
                        backgroundColor: "var(--background-grey-dark)",
                        border: "1px solid var(--background-green)",
                        borderRadius: "8px"
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        backgroundColor: "rgba(0,0,0,0)"
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: "var(--background-green)"
                        
                    },
                }
            },
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <MobileDatePicker
                className="anonymization-table-date-input"
                defaultValue={defaultValue !== undefined ? dayjs(formattedDefaultValue) : undefined}
                onAccept={onDateChange}
                disableFuture={true}
                slotProps={{
                    field: {
                        readOnly: true,
                    },
                    textField: {
                        placeholder: 'Enter Date...',
                    }
                }}
            />
        </ThemeProvider>
    )
}

export default DateInput