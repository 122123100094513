import React from 'react'
import { getDisplayNameFromDicomKeyword } from '../../lib/deidentification/getTagsForDisplay'
import InputComponent from './input/InputComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import "./AnonymizationTableRow.css"

import { Tooltip } from 'react-tooltip'

type AnonymizationTableProps = {
    dicomKeyword: string,
    originalValue: string,
    defaultValue?: string,
    fixed: boolean,
    tooltipMessage?: string,
    setNewDicomTag: (value: string) => void
    deleteRow?: (value: string) => void
}

const AnonymizationTableRow = ({ dicomKeyword, originalValue, defaultValue, fixed, tooltipMessage, setNewDicomTag, deleteRow }: AnonymizationTableProps) => {
    return (
        <tr>
            <td className='anonymization-table-cell anonymization-table-cell-tagname'>{getDisplayNameFromDicomKeyword(dicomKeyword)}</td>
            <td className='anonymization-table-cell'>{originalValue}</td>
            <InputComponent
                dicomKeyword={dicomKeyword}
                setNewDicomTag={setNewDicomTag}
                defaultValue={defaultValue}
                fixed={fixed}
            />
            {deleteRow ?
            <td className='anonymization-table-row-delete' onClick={() => deleteRow(dicomKeyword)}>
                <FontAwesomeIcon icon={faCircleXmark} />
            </td>
            : null}
            {tooltipMessage ?
                <React.Fragment>
                <td className='anonymization-table-row-tooltip-icon'>
                    <FontAwesomeIcon icon={faCircleQuestion} data-tooltip-id="replace-tooltip"/>
                </td>
                <Tooltip
                    id="replace-tooltip"
                    place="left"
                    content={tooltipMessage}
                    className='anonymization-table-row-tooltip'
                    style={{backgroundColor: 'var(--background-warning)', color: "var(--text-primary)"}}
                    opacity={1}
                />
                </React.Fragment>
            : null}
        </tr>
    )
}

export default AnonymizationTableRow