import React from "react";
import { DicomTagOverridesPerStudy, UploadLevel, useFileState } from "../../providers/FileStateProvider";
import "../NextPrevButton.css"
import { SitePatientInfo } from "../../../../sharedTypes/wmsQueryTypes";
import { generatePatientTagPatchConfig, generateWMSPatientInformation } from "../../lib/patientInformation/combinePatientInformation";
import { TagPatchConfig } from "../../../../sharedTypes/deidentifyTypes";
import { DICOMFormatPatientInfo } from "../../lib/patientInformation/getDICOMFormattedPatientInfo";
import { UserDefinedDeidentifyListPerStudy } from "../../lib/deidentification/types";


type AnonymizationAndAttachmentsFooterProps = {
    canUpload: boolean;
    onUpload: (
        newDicomTagOverridesPerStudy: DicomTagOverridesPerStudy,
        newUserDefinedDeidentifyListPerStudy: UserDefinedDeidentifyListPerStudy,
        patientInformation: SitePatientInfo | undefined,
        deidentifiedPatientTagPatchConfig: TagPatchConfig | undefined,
        redactBurnedInPHI: boolean,
        siteId?: string
    ) => void;
    removeBurnedInPHI: boolean;
    defaultPatientInfo?: DICOMFormatPatientInfo;
    userDefinedPatientInfo: Partial<DICOMFormatPatientInfo>;
    defaultDeidentifiedPatientInfo?: DICOMFormatPatientInfo;
    userDefinedDeidentifiedPatientInfo: Partial<DICOMFormatPatientInfo>;
    newDicomTagOverridesPerStudy: DicomTagOverridesPerStudy;
    newUserDefinedDeidentifyListPerStudy: UserDefinedDeidentifyListPerStudy;
    selectedSiteId?: number;
}


function AnonymizationAndAttachmentsFooter({
    canUpload,
    onUpload,
    removeBurnedInPHI,
    defaultPatientInfo,
    userDefinedPatientInfo,
    defaultDeidentifiedPatientInfo,
    userDefinedDeidentifiedPatientInfo,
    newDicomTagOverridesPerStudy,
    newUserDefinedDeidentifyListPerStudy,
    selectedSiteId
}: AnonymizationAndAttachmentsFooterProps) {
    const {
        api,
        manualRedactBurnedInPHI,
        mandatoryRedactBurnedInPHI,
        uploadInput
    } = useFileState()

    const uploadButtonClassName = canUpload ? "next-prev-button" : "next-prev-button next-prev-button_disabled"

    return (
        <div>
            <button className="next-prev-button" onClick={() => {
                api.mutateDicomTagOverridesPerStudy(newDicomTagOverridesPerStudy)
                api.mutateUserDefinedDeidentifyList(newUserDefinedDeidentifyListPerStudy)
                api.setManualRedactBurnedInPHI(removeBurnedInPHI)
                api.resetState()
            }}>
                Back
            </button>
            <button className={uploadButtonClassName} disabled={!canUpload} onClick={() => {


                api.mutateDicomTagOverridesPerStudy(newDicomTagOverridesPerStudy)
                api.mutateUserDefinedDeidentifyList(newUserDefinedDeidentifyListPerStudy)
                api.setManualRedactBurnedInPHI(removeBurnedInPHI)
                // Pass these in to the functional API so we don't have to wait for a state update.
                // The state update is just incase we want to navigate back and edit.
                const redactBurnedInPHI = mandatoryRedactBurnedInPHI ? true : removeBurnedInPHI

                let patientInformation: SitePatientInfo | undefined = undefined;
                let deidentifiedPatientTagPatchConfig: TagPatchConfig | undefined = undefined;

                if (uploadInput.uploadLevel === UploadLevel.TRIAL || uploadInput.uploadLevel === UploadLevel.PATIENT) {
                    patientInformation = generateWMSPatientInformation(defaultPatientInfo, userDefinedPatientInfo);
                }

                if (uploadInput.deidentifying && uploadInput.uploadLevel !== UploadLevel.ORGANIZATION) {
                    deidentifiedPatientTagPatchConfig = generatePatientTagPatchConfig(defaultDeidentifiedPatientInfo, userDefinedDeidentifiedPatientInfo);
                }

                onUpload(
                    newDicomTagOverridesPerStudy,
                    newUserDefinedDeidentifyListPerStudy,
                    patientInformation,
                    deidentifiedPatientTagPatchConfig,
                    redactBurnedInPHI,
                    selectedSiteId === undefined ? undefined : String(selectedSiteId)
                )
            }}>
                Upload
            </button>
        </div>
    )
}

export default AnonymizationAndAttachmentsFooter