import { DeidentificationProtocol, TagPatchConfig } from "../../../../sharedTypes/deidentifyTypes"
import { DicomTagOverridesPerStudy } from "./types"
import { TextRedactionMode, DeidentifyConfig } from "../../../../sharedTypes/deidentifyTypes"
import { generateTagPatchConfigPerStudy } from "./generateTagPatchConfigPerStudy"
import { StudyMetadata } from "../../providers/FileStateProvider"


const getTextRedactionMode = (redactBurnedInPHI: boolean): TextRedactionMode | undefined => {
    if (redactBurnedInPHI) {
        return "REDACT_SENSITIVE_TEXT"
    }
}

const generateDeidentifyConfig = (
    deidentifiedPatientTagPatchConfig: TagPatchConfig | undefined,
    mandatoryDeidentifyList: DeidentificationProtocol,
    userDefinedDeidentifyListPerStudy: Record<string, string[]>,
    dicomOverridesPerStudy: DicomTagOverridesPerStudy,
    redactBurnedInPHI: boolean,
    studyMetadata: StudyMetadata[]
): DeidentifyConfig => {
    return {
        textRedactionMode: getTextRedactionMode(redactBurnedInPHI),
        tagPatchConfigPerStudy: generateTagPatchConfigPerStudy(
            mandatoryDeidentifyList,
            userDefinedDeidentifyListPerStudy,
            dicomOverridesPerStudy,
            studyMetadata
        ),
        patientTagPatchConfig: deidentifiedPatientTagPatchConfig
    }
}

export { generateDeidentifyConfig }