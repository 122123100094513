import React, { useState } from "react";
// import { useFileState } from "../../providers/FileStateProvider";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DICOMFormatPatientInfo } from "../../lib/patientInformation/getDICOMFormattedPatientInfo";
import "./PatientInfoInput.css"
import DeidentifiedPatientInfoRow from './DeidentifiedPatientInfoRow'
import PatientInfoRow from "./PatientInfoRow";


type DeidentifiedPatientInfoArgs = {
  title: string;
  isDeidentifiedOrderLevelUpload: boolean // if so, only show new value column.
  defaultIdentifiedPatientInfo?: DICOMFormatPatientInfo,
  setNewIdentifiedValue: (keyword: "PatientID" | "PatientName" | "PatientBirthDate" | "PatientSex", value: string) => void,
  defaultDeidentifiedPatientInfo?: DICOMFormatPatientInfo,
  setNewDeidentifiedValue: (keyword: "PatientID" | "PatientName" | "PatientBirthDate" | "PatientSex", value: string) => void
}

const DeidentifiedPatientInfoInput = ({
    title,
    isDeidentifiedOrderLevelUpload,
    defaultIdentifiedPatientInfo,
    setNewIdentifiedValue,
    defaultDeidentifiedPatientInfo,
    setNewDeidentifiedValue
}: DeidentifiedPatientInfoArgs) => {

    const [identifiedPatientId, setIdentifiedPatientId] = useState<string>(defaultIdentifiedPatientInfo?.PatientID.value || '')
    const [deidentifiedPatientId, setDeidentifiedPatientId] = useState<string>(defaultDeidentifiedPatientInfo?.PatientID.value || '')

    const setNewIdentifiedPatientID = (patientIdValue: string): void => {
      setNewIdentifiedValue("PatientID", patientIdValue)
      setIdentifiedPatientId(patientIdValue)
    }

    const setNewDeidentifiedPatientID = (patientIdValue: string): void => {
      setNewDeidentifiedValue("PatientID", patientIdValue)
      setDeidentifiedPatientId(patientIdValue)
    }

    if (isDeidentifiedOrderLevelUpload) {
      /**
       * Note: the LocalizationProvider is required to drive any date picker components in the table hierarchy.
       */
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div>
            <h2 className='patient-info-table-header'>{title}</h2>
            <table className='patient-info-table'>
              <tbody>
                  <tr>
                      <th></th>
                      <th>New Value</th>
                  </tr>
                <PatientInfoRow
                  displayName="Medical Record Number"
                  dicomKeyword={"PatientID"}
                  setNewValue={setNewDeidentifiedPatientID}
                  fixed={true}
                  defaultValue={defaultDeidentifiedPatientInfo?.PatientID.value || ''}
                />
                <PatientInfoRow
                  displayName="Patient Name"
                  dicomKeyword={"PatientName"}
                  setNewValue={(value: any) => setNewDeidentifiedValue("PatientName", value)}
                  fixed={true}
                  defaultValue={defaultDeidentifiedPatientInfo?.PatientName.value || ''}
                />
                <PatientInfoRow
                  displayName="Date Of Birth"
                  dicomKeyword={"PatientBirthDate"}
                  setNewValue={(value: any) => setNewDeidentifiedValue("PatientBirthDate", value)}
                  fixed={true}
                  defaultValue={defaultDeidentifiedPatientInfo?.PatientBirthDate.value || ''}
                />
                <PatientInfoRow
                  displayName="Gender"
                  dicomKeyword={"PatientSex"}
                  setNewValue={(value: any) => setNewDeidentifiedValue("PatientSex", value)}
                  fixed={true}
                  defaultValue={defaultDeidentifiedPatientInfo?.PatientSex.value || ''}
                />
              </tbody>
            </table>
          </div>
          {identifiedPatientId === deidentifiedPatientId ?
            <p className="patient-info-input-warning">
              The new Medical Record Number cannot be the the same as the original <br/>Medical Record Number.
            </p>
            : null
          }
        </LocalizationProvider>
      );
    }
  
    /**
     * Note: the LocalizationProvider is required to drive any date picker components in the table hierarchy.
     */
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
          <h2 className='patient-info-table-header'>{title}</h2>
          <table className='patient-info-table'>
            <tbody>
                <tr>
                    <th></th>
                    <th>Original Value</th>
                    <th>New Value</th>
                </tr>
              <DeidentifiedPatientInfoRow
                displayName="Medical Record Number"
                dicomKeyword={"PatientID"}
                setNewIdentifiedValue={setNewIdentifiedPatientID}
                defaultIdentifiedValue={defaultIdentifiedPatientInfo?.PatientID.value || ''}
                identifiedValueFixed={defaultIdentifiedPatientInfo?.PatientID.locked || false}
                setNewDeidentifiedValue={setNewDeidentifiedPatientID}
                defaultDeidentifiedValue={defaultDeidentifiedPatientInfo?.PatientID.value || ''}
                deidentifiedValueFixed={defaultDeidentifiedPatientInfo?.PatientID.locked || false}
              />
              <DeidentifiedPatientInfoRow
                displayName="Patient Name"
                dicomKeyword={"PatientName"}
                setNewIdentifiedValue={(value: any) => setNewIdentifiedValue("PatientName", value)}
                defaultIdentifiedValue={defaultIdentifiedPatientInfo?.PatientName.value || ''}
                identifiedValueFixed={defaultIdentifiedPatientInfo?.PatientName.locked || false}
                setNewDeidentifiedValue={(value: any) => setNewDeidentifiedValue("PatientName", value)}
                defaultDeidentifiedValue={defaultDeidentifiedPatientInfo?.PatientName.value || ''}
                deidentifiedValueFixed={defaultDeidentifiedPatientInfo?.PatientName.locked || false}
              />
              <DeidentifiedPatientInfoRow
                displayName="Date Of Birth"
                dicomKeyword={"PatientBirthDate"}
                setNewIdentifiedValue={(value: any) => setNewIdentifiedValue("PatientBirthDate", value)}
                defaultIdentifiedValue={defaultIdentifiedPatientInfo?.PatientBirthDate.value || ''}
                identifiedValueFixed={defaultIdentifiedPatientInfo?.PatientBirthDate.locked || false}
                setNewDeidentifiedValue={(value: any) => setNewDeidentifiedValue("PatientBirthDate", value)}
                defaultDeidentifiedValue={defaultDeidentifiedPatientInfo?.PatientBirthDate.value || ''}
                deidentifiedValueFixed={defaultDeidentifiedPatientInfo?.PatientBirthDate.locked || false}
              />
              <DeidentifiedPatientInfoRow
                displayName="Gender"
                dicomKeyword={"PatientSex"}
                setNewIdentifiedValue={(value: any) => setNewIdentifiedValue("PatientSex", value)}
                defaultIdentifiedValue={defaultIdentifiedPatientInfo?.PatientSex.value || ''}
                identifiedValueFixed={defaultIdentifiedPatientInfo?.PatientSex.locked || false}
                setNewDeidentifiedValue={(value: any) => setNewDeidentifiedValue("PatientSex", value)}
                defaultDeidentifiedValue={defaultDeidentifiedPatientInfo?.PatientSex.value || ''}
                deidentifiedValueFixed={defaultDeidentifiedPatientInfo?.PatientSex.locked || false}
              />
            </tbody>
          </table>
        </div>
        {identifiedPatientId === deidentifiedPatientId ?
          <p className="patient-info-input-warning">
            The new Medical Record Number cannot be the the same as the original <br/>Medical Record Number.
          </p>
          : null
        }
      </LocalizationProvider>
    );
}


export default DeidentifiedPatientInfoInput