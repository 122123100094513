import { DICOMFormatPatientInfo, PatientSexValue } from "./types";
import { UploadInput } from "../../providers/FileStateProvider";
import { searchForDeidentifiedPatient } from "../api/wms";
import { wmsDateToDICOMDate, firstAndLastNameToDICOMPatientName, getDicomPatientSexCodeValueFromWMSGender } from "./utils";

const generateEmptyDeidentifiedPatientInfo = (defaults: { PatientSex: PatientSexValue, PatientBirthDate?: string }): DICOMFormatPatientInfo => {
    return {
        PatientID: {
            value: '',
            locked: false
        },
        PatientName: {
            value: '',
            locked: false
        },
        PatientSex: {
            value: defaults.PatientSex,
            locked: false
        },
        PatientBirthDate: {
            value: defaults.PatientBirthDate || '',
            locked: false
        },
    }
}

type DeidPatientInfoGenerationProps = {
    FirstName: string;
    LastName: string;
    DateOfBirth: string;
    Gender: 'Male' | 'Female' | 'Unknown';
    PatientID?: string
}

const buildDICOMFormattedDeidentifiedPatientInfo = (props: DeidPatientInfoGenerationProps) => {
    const deidentifiedPatientInfo = generateEmptyDeidentifiedPatientInfo({ PatientSex: "O" });
    const { FirstName, LastName, DateOfBirth, Gender, PatientID } = props;

    deidentifiedPatientInfo.PatientBirthDate = {
        value: wmsDateToDICOMDate(DateOfBirth),
        locked: true
    }
    deidentifiedPatientInfo.PatientName = {
        value: firstAndLastNameToDICOMPatientName(FirstName, LastName),
        locked: true
    }
    deidentifiedPatientInfo.PatientSex = {
        value: getDicomPatientSexCodeValueFromWMSGender(Gender),
        locked: true
    }

    // Note in the case of a deidentified patient with no site patient match, we may not have an MRN to fill in.
    if (PatientID) {
        deidentifiedPatientInfo.PatientID = {
            value: PatientID,
            locked: true
        }
    }

    return deidentifiedPatientInfo
}

/**
 * For deidentified patient info:
 * --> We find no patient, therefore no deid patient => Leave all blank and make enter.
 * --> We find a patient, but no deid patient => Leave all blank and make enter.
 * --> We find a patient, a deid patient, but not matching deid site patient, => Fill all in but MRN and make enter.
 * --> We find a patient, and find an existing deid patient and a deid site patient matching this MRN => Fill all in and lock.
 */
const getDICOMFormattedDeidentifiedPatientInfo = async (
    uploadInput: UploadInput,
    options: {
        patientId?: number,
        sitePatientId?: number,
        defaultPatientSexIfNoMatch: PatientSexValue,
        defaultPatientBirthDateIfNoMatch: string
    }

): Promise<DICOMFormatPatientInfo> => {
    const { patientId, sitePatientId, defaultPatientSexIfNoMatch, defaultPatientBirthDateIfNoMatch } = options;
    if (patientId === undefined) {
        return generateEmptyDeidentifiedPatientInfo({
            PatientSex: defaultPatientSexIfNoMatch,
            PatientBirthDate: defaultPatientBirthDateIfNoMatch
        });
    }

    const deidentifiedPatient = await searchForDeidentifiedPatient(uploadInput, patientId);

    if (deidentifiedPatient === undefined) {
        return generateEmptyDeidentifiedPatientInfo({
            PatientSex: defaultPatientSexIfNoMatch,
            PatientBirthDate: defaultPatientBirthDateIfNoMatch
        });
    }

    const deidSitePatient = sitePatientId === undefined ? undefined : deidentifiedPatient.sitePatients.find(sp => sp.sitePatientId === sitePatientId);

    let deidentifiedMrn: string | undefined = undefined;

    if (deidSitePatient !== undefined) {
        // We have a match, return this MRN
        deidentifiedMrn = deidSitePatient.deidentifiedMrn;
    }

    const { FirstName, LastName, DateOfBirth, Gender } = deidentifiedPatient;

    return buildDICOMFormattedDeidentifiedPatientInfo({
        FirstName,
        LastName,
        DateOfBirth,
        Gender,
        PatientID: deidentifiedMrn
    })
}

export { getDICOMFormattedDeidentifiedPatientInfo, buildDICOMFormattedDeidentifiedPatientInfo }