import React, { useEffect, useState } from "react";
import { DicomTagOverridesPerStudy, StudyMetadata, UploadLevel, useFileState } from "../../providers/FileStateProvider";
import Attachments from "../Attachments";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Anonymization from '../Anonymization/Anonymization'
import "./AnonymizationAndAttachments.css"
import { UserDefinedDeidentifyListPerStudy } from "../../lib/deidentification/types";
import { searchForPatientByMrn } from "../../lib/api/wms";
import { PatientInfo, SitePatientInfo } from "../../../../sharedTypes/wmsQueryTypes";
import SiteSelector from "./SiteSelector";
import { getDICOMFormattedPatientInfo, DICOMFormatPatientInfo } from "../../lib/patientInformation/getDICOMFormattedPatientInfo";
import PatientInfoInput from "../PatientInfoInput/PatientInfoInput";
import { buildDICOMFormattedDeidentifiedPatientInfo, getDICOMFormattedDeidentifiedPatientInfo } from "../../lib/patientInformation/getDICOMFormattedDeidentifiedPatientInfo";
import { TagPatchConfig } from "../../../../sharedTypes/deidentifyTypes";
import AnonymizationAndAttachmentsFooter from "./AnonymizationAndAttachmentsFooter";

type AnonymizationAndAttachmentsProps = {
    onUpload: (
        newDicomTagOverridesPerStudy: DicomTagOverridesPerStudy,
        newUserDefinedDeidentifyListPerStudy: UserDefinedDeidentifyListPerStudy,
        patientInformation: SitePatientInfo | undefined,
        deidentifiedPatientTagPatchConfig: TagPatchConfig | undefined,
        redactBurnedInPHI: boolean,
        siteId?: string
    ) => void,
    canAddAttachments: boolean
}

/**
 * Reducer for setState methods for patient info and deidentified patient info, which returns new state with the key/value pair added or overwritten.
 */
const patientInfoReducer = (oldPatientInfo: Partial<DICOMFormatPatientInfo>, keyword: "PatientID" | "PatientName" | "PatientBirthDate" | "PatientSex", value: string) => {
    const newPatientInfo = { ...oldPatientInfo }

    if (keyword === "PatientSex") {
        if (!["M", "F", "O"].includes(value)) {
            throw new Error('Invalid CS value for PatientSex')
        }
        const codeStringValue = value as "M" | "F" | "O";

        newPatientInfo['PatientSex'] = {
            value: codeStringValue,
            locked: false
        }
    } else {
        newPatientInfo[keyword] = {
            value,
            locked: false
        }
    }

    return newPatientInfo;
}

const getDicomMrn = (studyMetadata: StudyMetadata[]): string => {
    const firstStudy = studyMetadata[0]

    const { header } = firstStudy

    return header.PatientID
}

const patientInformationComplete = (defaultPatientInfo: DICOMFormatPatientInfo | undefined, userDefinedPatientInfo: Partial<DICOMFormatPatientInfo>): boolean => {
    if (!defaultPatientInfo?.PatientBirthDate.value.length && !userDefinedPatientInfo.PatientBirthDate?.value.length) {
        return false;
    }
    if (!defaultPatientInfo?.PatientID.value.length && !userDefinedPatientInfo.PatientID?.value.length) {
        return false;
    }
    if (!defaultPatientInfo?.PatientName.value.length && !userDefinedPatientInfo.PatientName?.value.length) {
        return false;
    }
    if (!defaultPatientInfo?.PatientSex.value.length && !userDefinedPatientInfo.PatientSex?.value.length) {
        return false;
    }

    return true;
}

const sameIdentifiedAndDeidentifiedMRNs = (
    defaultPatientInfo: DICOMFormatPatientInfo | undefined,
    userDefinedPatientInfo: Partial<DICOMFormatPatientInfo>,
    defaultDeidentifiedPatientInfo: DICOMFormatPatientInfo | undefined,
    userDefinedDeidentifiedPatientInfo: Partial<DICOMFormatPatientInfo>
): boolean => {
    const identifiedMRN = userDefinedPatientInfo.PatientID?.value.length ? userDefinedPatientInfo.PatientID.value : defaultPatientInfo?.PatientID.value
    const deidentifiedMRN = userDefinedDeidentifiedPatientInfo.PatientID?.value.length ? userDefinedDeidentifiedPatientInfo.PatientID.value : defaultDeidentifiedPatientInfo?.PatientID.value

    return identifiedMRN === deidentifiedMRN
}

const verifyUpload = (
    isTrialOrTrialPatientLevelUpload: boolean,
    selectedSiteId: number | undefined,
    defaultPatientInfo: DICOMFormatPatientInfo | undefined,
    userDefinedPatientInfo: Partial<DICOMFormatPatientInfo>,
    deidentifying: boolean,
    defaultDeidentifiedPatientInfo: DICOMFormatPatientInfo | undefined,
    userDefinedDeidentifiedPatientInfo: Partial<DICOMFormatPatientInfo>
): boolean => {
    if (!isTrialOrTrialPatientLevelUpload) {
        // Not on a trial or trialPatient level upload, nothing to verify.
        return true;
    }

    // Have we selected a site?
    if (selectedSiteId === undefined) {
        return false;
    }

    // Verify all the patient information is filled in.
    if (!patientInformationComplete(defaultPatientInfo, userDefinedPatientInfo)) {
        return false;
    }

    // Verify all the deidentified patient information is filled in.
    if (deidentifying) {
        if (!patientInformationComplete(defaultDeidentifiedPatientInfo, userDefinedDeidentifiedPatientInfo)) {
            return false;
        }
        if (sameIdentifiedAndDeidentifiedMRNs(
            defaultPatientInfo,
            userDefinedPatientInfo,
            defaultDeidentifiedPatientInfo,
            userDefinedDeidentifiedPatientInfo
        )) {
            return false
        }
    }



    return true;
}

const AnonymizationAndAttachments = ({ onUpload, canAddAttachments }: AnonymizationAndAttachmentsProps) => {
    const {
        dicomTagOverridesPerStudy,
        userDefinedDeidentifyListPerStudy,
        manualRedactBurnedInPHI,
        mandatoryRedactBurnedInPHI,
        mandatoryDeidentifyList,
        studyMetadata,
        uploadInput
    } = useFileState()

    const [selectedSiteId, setSelectedSiteId] = useState<number | undefined>(undefined)

    /**
     * Identified Patient
     */
    const [defaultPatientInfo, setDefaultPatientInfo] = useState<DICOMFormatPatientInfo | undefined>(undefined)
    const [userDefinedPatientInfo, setUserDefinedPatientInfo] = useState<Partial<DICOMFormatPatientInfo>>({})

    /**
     * Deidentified Patient
     */
    const [defaultDeidentifiedPatientInfo, setDefaultDeidentifiedPatientInfo] = useState<DICOMFormatPatientInfo | undefined>(undefined)
    const [userDefinedDeidentifiedPatientInfo, setUserDefinedDeidentifiedPatientInfo] = useState<Partial<DICOMFormatPatientInfo>>({})

    const [canUpload, setCanUpload] = useState<boolean>(false);
    const [loading, setIsLoading] = useState<boolean>(true);

    const [showSiteSelection, setShowSiteSelection] = useState<boolean>(false);

    /**
     * Include for trial and patient scoped.
     * Don't need for order scoped since its fully defined and the BE can deal with it.
     */
    const isTrialOrTrialPatientLevelUpload = uploadInput.uploadLevel === UploadLevel.TRIAL || uploadInput.uploadLevel === UploadLevel.PATIENT
    const areDeidentifying = uploadInput.deidentifying
    const isDeidentifiedOrderLevelUpload = uploadInput.uploadLevel === UploadLevel.ORDER && areDeidentifying
    const isTrialLevelGuestUploader = uploadInput.guestUploader !== undefined && uploadInput.uploadLevel === UploadLevel.TRIAL

    /**
     * Determines if this is a trial level upload, to a blinded trial.
     */
    const isBlindedTrial: boolean = !!uploadInput.trial?.isBlindedTrial

    /**
     * Verify if all input data is sufficient for upload
     */
    useEffect(() => {
        setCanUpload(verifyUpload(
            isTrialOrTrialPatientLevelUpload,
            selectedSiteId,
            defaultPatientInfo,
            userDefinedPatientInfo,
            uploadInput.deidentifying,
            defaultDeidentifiedPatientInfo,
            userDefinedDeidentifiedPatientInfo
        ))
    }, [
        uploadInput,
        isTrialOrTrialPatientLevelUpload,
        selectedSiteId,
        defaultPatientInfo,
        userDefinedPatientInfo,
        defaultDeidentifiedPatientInfo,
        userDefinedDeidentifiedPatientInfo
    ])

    const setNewPatientValue = (keyword: "PatientID" | "PatientName" | "PatientBirthDate" | "PatientSex", value: string) => {
        setUserDefinedPatientInfo((oldUserDefinedPatientInfo) => {
            return patientInfoReducer(oldUserDefinedPatientInfo, keyword, value);
        })
    }

    const setNewDeidentifiedPatientValue = (keyword: "PatientID" | "PatientName" | "PatientBirthDate" | "PatientSex", value: string) => {
        setUserDefinedDeidentifiedPatientInfo((oldUserDefinedDeidentifiedPatientInfo) => {
            return patientInfoReducer(oldUserDefinedDeidentifiedPatientInfo, keyword, value);
        })
    }

    const searchForAndSelectSite = async (): Promise<{ patientId?: number, sitePatientId?: number, patientInfo?: PatientInfo }> => {
        const mrn = getDicomMrn(studyMetadata)

        let siteId: number | undefined = undefined
        let patientId: number | undefined = undefined;
        let sitePatientId: number | undefined = undefined;
        let patientInfo: PatientInfo | undefined = undefined;

        if (uploadInput.uploadLevel === UploadLevel.PATIENT) {
            const patient = uploadInput.patient

            if (!patient) {
                throw new Error('No patient for patient level upload');
            }

            // Just compare against the MRNs we know for this patient to get potential site
            const sitePatientForMrn = patient.sitePatients.find(sp => sp.MedicalRecordNumber === mrn)

            patientId = patient.id

            patientInfo = {
                FirstName: patient.FirstName,
                LastName: patient.LastName,
                DateOfBirth: patient.DateOfBirth,
                Gender: patient.Gender
            }

            if (sitePatientForMrn) {
                siteId = sitePatientForMrn.siteId
                sitePatientId = sitePatientForMrn.id
            }
        } else {
            // Trial level
            const searchForPatientByMrnResult = await searchForPatientByMrn(uploadInput, mrn)

            if (searchForPatientByMrnResult && searchForPatientByMrnResult.items.length) {
                const sitePatient = searchForPatientByMrnResult.items[0];
                siteId = sitePatient.siteId;
                patientId = sitePatient.patientId;
                sitePatientId = sitePatient.sitePatientId;
                patientInfo = sitePatient.patientInfo;
            }
        }

        setSelectedSiteId(siteId)

        return {
            patientId,
            sitePatientId,
            patientInfo
        }
    }

    const setPatientInfoFromWMSResultOrDicom = (studyMetadata: StudyMetadata[], patientInfoFromWMS?: PatientInfo) => {
        const dicomFormattedPatientInfo = getDICOMFormattedPatientInfo(studyMetadata, patientInfoFromWMS)

        setDefaultPatientInfo(dicomFormattedPatientInfo);

        return dicomFormattedPatientInfo;
    }

    const patientMatching = async (): Promise<void> => {
        let shouldShowSiteSelection = true;

        if (uploadInput.uploadLevel === UploadLevel.ORDER) {
            // Site defined by order.
            shouldShowSiteSelection = false;

            if (uploadInput.patient && uploadInput.deidentifying) {
                // If deidentifying into an order, display the order's patient info, as this is the exact target
                // for deidentification.
                const { FirstName, LastName, DateOfBirth, Gender } = uploadInput.patient
                const deidentifiedPatientInfo = await buildDICOMFormattedDeidentifiedPatientInfo({
                    FirstName,
                    LastName,
                    DateOfBirth,
                    Gender,
                    PatientID: uploadInput.order?.MedicalRecordNumber
                })

                setDefaultDeidentifiedPatientInfo(deidentifiedPatientInfo)
            }
        } else {
            const { patientId, sitePatientId, patientInfo } = await searchForAndSelectSite();

            const dicomFormattedPatientInfo = setPatientInfoFromWMSResultOrDicom(studyMetadata, patientInfo)

            if (uploadInput.deidentifying) {
                const patientBirthDate = dicomFormattedPatientInfo.PatientBirthDate.value;

                const defaultPatientSexIfNoMatch = studyMetadata[0].header.PatientSex ?? 'O';
                let defaultPatientBirthDateIfNoMatch = '';

                if (patientBirthDate.length) {
                    defaultPatientBirthDateIfNoMatch = `${dicomFormattedPatientInfo.PatientBirthDate.value.slice(0, 4)}0101`;
                }

                const deidentifiedPatientInfo = await getDICOMFormattedDeidentifiedPatientInfo(
                    uploadInput,
                    {
                        patientId,
                        sitePatientId,
                        defaultPatientSexIfNoMatch,
                        defaultPatientBirthDateIfNoMatch
                    })

                if (deidentifiedPatientInfo.PatientID.locked) {
                    // If we have a fixed deidentified MRN, the site is already defined by the deidentified patient relationship.
                    shouldShowSiteSelection = false;
                }

                setDefaultDeidentifiedPatientInfo(deidentifiedPatientInfo)
            }


        }

        setShowSiteSelection(shouldShowSiteSelection)
        setIsLoading(false);
    }

    /**
     * Search for the patient based on the MRN on page load.
     * Don't do this for blind trial uploads, we will always create a new patient.
     */
    useEffect(() => {
        if (isTrialLevelGuestUploader) {
            setSelectedSiteId(Number(uploadInput.guestUploader?.site_id));
            setPatientInfoFromWMSResultOrDicom(studyMetadata)
            setIsLoading(false);
        } else if ((isTrialOrTrialPatientLevelUpload || isDeidentifiedOrderLevelUpload) && !isBlindedTrial) {
            /**
             * For trial and patient level search and select the site Id.
             */
            patientMatching();
        } else {
            setIsLoading(false);
        }
    }, [])

    const [showAttachments, setShowAttachments] = useState<boolean>(true)
    const [showDeidentification, setShowDeidentification] = useState<boolean>(true)

    // Note this is the local state, which we then add to the global state after
    // we confirm or go back.
    const [newDicomTagOverridesPerStudy, setNewDicomTagOverridesPerStudy] = useState<DicomTagOverridesPerStudy>(dicomTagOverridesPerStudy)
    const [newUserDefinedDeidentifyListPerStudy, setNewUserDefinedDeidentifyListPerStudy] = useState<UserDefinedDeidentifyListPerStudy>(userDefinedDeidentifyListPerStudy)
    const [removeBurnedInPHI, setRemoveBurnedInPHI] = useState<boolean>(manualRedactBurnedInPHI)

    const setNewDicomTagOverrideForStudy = (StudyInstanceUID: string, keyword: string, value: string) => {
        setNewDicomTagOverridesPerStudy((oldDicomOverridesPerStudy) => {
            const newTagsPerStudy = { ...oldDicomOverridesPerStudy }

            if (newTagsPerStudy[StudyInstanceUID] === undefined) {
                newTagsPerStudy[StudyInstanceUID] = {}
            }

            newTagsPerStudy[StudyInstanceUID][keyword] = value

            return newTagsPerStudy
        })
    }

    const onSiteSelection = (siteId: number): void => {
        setSelectedSiteId(siteId)
    }

    const addNewUserDefinedRowForStudy = (StudyInstanceUID: string, keyword: string) => {
        setNewUserDefinedDeidentifyListPerStudy((oldUserDefinedDeidentifyListPerStudy) => {
            const newUserDefinedListPerStudy = { ...oldUserDefinedDeidentifyListPerStudy }

            if (newUserDefinedListPerStudy[StudyInstanceUID] === undefined) {
                newUserDefinedListPerStudy[StudyInstanceUID] = []
            }

            newUserDefinedListPerStudy[StudyInstanceUID] = [...newUserDefinedListPerStudy[StudyInstanceUID], keyword]

            return newUserDefinedListPerStudy
        })
    }

    const deleteUserDefinedRowForStudy = (StudyInstanceUID: string, keyword: string) => {
        setNewUserDefinedDeidentifyListPerStudy((oldUserDefinedDeidentifyListPerStudy) => {
            const newUserDefinedListPerStudy = { ...oldUserDefinedDeidentifyListPerStudy }

            if (newUserDefinedListPerStudy[StudyInstanceUID] !== undefined) {
                const filteredKeywords = newUserDefinedListPerStudy[StudyInstanceUID].filter(kw => kw !== keyword)
                newUserDefinedListPerStudy[StudyInstanceUID] = filteredKeywords
            }

            return newUserDefinedListPerStudy
        })
    }

    if (loading) {
        return <div>Loading...</div>
    }

    const AnonymizationWithDropdown = (
        <div>
            <div className="anon-and-attachments-dropdown">
                <FontAwesomeIcon
                    className="anon-and-attachments-dropdown-chevron"
                    icon={showDeidentification ? faChevronDown : faChevronRight}
                    onClick={() => setShowDeidentification(!showDeidentification)}
                />
                <h3>Anonymization</h3>
            </div>
            <Anonymization
                show={showDeidentification}
                showDeidentifiedPatientInfo={isTrialOrTrialPatientLevelUpload || isDeidentifiedOrderLevelUpload}
                isDeidentifiedOrderLevelUpload={isDeidentifiedOrderLevelUpload}
                defaultIdentifiedPatientInfo={defaultPatientInfo}
                setNewIdentifiedPatientValue={setNewPatientValue}
                defaultDeidentifiedPatientInfo={defaultDeidentifiedPatientInfo}
                setNewDeidentifiedPatientValue={setNewDeidentifiedPatientValue}
                setNewDicomTagOverrideForStudy={setNewDicomTagOverrideForStudy}
                addNewUserDefinedRowForStudy={addNewUserDefinedRowForStudy}
                deleteUserDefinedRowForStudy={deleteUserDefinedRowForStudy}
                newUserDefinedDeidentifyListPerStudy={newUserDefinedDeidentifyListPerStudy}
                removeBurnedInPHI={removeBurnedInPHI}
                setRemoveBurnedInPHI={setRemoveBurnedInPHI}
                mandatoryRedactBurnedInPHI={mandatoryRedactBurnedInPHI}
                mandatoryDeidentifyList={mandatoryDeidentifyList}
            />
        </div>
    );

    const AttachmentsWithDropdown = (
        <div>
            <div className="anon-and-attachments-dropdown">
                <FontAwesomeIcon
                    className="anon-and-attachments-dropdown-chevron"
                    icon={showAttachments ? faChevronDown : faChevronRight}
                    onClick={() => setShowAttachments(!showAttachments)}
                />
                <h3>Attachments</h3>
            </div>
            <Attachments show={showAttachments} />
        </div>
    );

    /**
     * Include Patient information at the top for identified trial and patient scoped uploads.
     * Don't need for order scoped since its fully defined and the BE can deal with it.
     * Don't need for deidentified uploads as then we use different UI within the Anonymization
     * UI.
     */
    return (
        <div className="anon-and-attachments">
            {isTrialOrTrialPatientLevelUpload && !areDeidentifying ?
                <PatientInfoInput
                    title={'Patient Information'}
                    defaultPatientInfo={defaultPatientInfo}
                    setNewValue={setNewPatientValue}
                />
                : null
            }
            {areDeidentifying ? AnonymizationWithDropdown : null}
            {canAddAttachments ? AttachmentsWithDropdown : null}
            {showSiteSelection && uploadInput.trial ?
                <SiteSelector
                    selectedSiteId={selectedSiteId}
                    trialSites={uploadInput.trial.trialSites}
                    sitePatients={uploadInput.patient?.sitePatients}
                    onChange={onSiteSelection}
                    isIdentifiedPatientLevelUpload={uploadInput.uploadLevel === UploadLevel.PATIENT && !uploadInput.deidentifying}
                    identifiedMrn={getDicomMrn(studyMetadata)}
                /> : null
            }
            <AnonymizationAndAttachmentsFooter
                canUpload={canUpload}
                onUpload={onUpload}
                removeBurnedInPHI={removeBurnedInPHI}
                defaultPatientInfo={defaultPatientInfo}
                userDefinedPatientInfo={userDefinedPatientInfo}
                defaultDeidentifiedPatientInfo={defaultDeidentifiedPatientInfo}
                userDefinedDeidentifiedPatientInfo={userDefinedDeidentifiedPatientInfo}
                newDicomTagOverridesPerStudy={newDicomTagOverridesPerStudy}
                newUserDefinedDeidentifyListPerStudy={newUserDefinedDeidentifyListPerStudy}
                selectedSiteId={selectedSiteId}
            />

        </div>
    );
}


export default AnonymizationAndAttachments