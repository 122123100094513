import React from 'react';
import { SitePatientsForPatientResponse, TrialResponse } from '../../../../sharedTypes/wmsQueryTypes';
import StatelessSelectInput from './input/StatelessSelectInput';
import { Option } from './input/StyledReactSelect'
import "./SiteSelector.css"


type SiteSelectorProps = {
    selectedSiteId?: number;
    trialSites: TrialResponse['trialSites'];
    sitePatients?: SitePatientsForPatientResponse['sitePatients'];
    isIdentifiedPatientLevelUpload: boolean;
    identifiedMrn: string;
    onChange: (siteId: number) => void;
}

const trialSitesToOptions = (trialSites: TrialResponse['trialSites']): Option[] => {
    return trialSites.map(ts => ({value: String(ts.siteId), label: ts.name}))
}

/**
 * If we have site patients, display the MRN for ease of use.
 * If we are doing an identified patient upload => Make sure options with different MRN to the data are not selectable.
 */
const getMrnLabelledOptions = (
    trialSites: TrialResponse['trialSites'],
    isIdentifiedPatientLevelUpload: boolean,
    identifiedMrn: string,
    sitePatients: SitePatientsForPatientResponse['sitePatients'],
): { options: Option[], disabledCount: number } => {
    const options = trialSitesToOptions(trialSites)

    let disabledCount = 0

    options.forEach(option => {
        const siteId = option.value

        const sitePatient = sitePatients.find(sp => String(sp.siteId) === siteId)

        if (sitePatient) {
            const { MedicalRecordNumber } = sitePatient;
            option.label = `${option.label} (${MedicalRecordNumber})`

            if (isIdentifiedPatientLevelUpload && identifiedMrn !== MedicalRecordNumber) {
                // For identified patient level upload, we cannot choose this site as its for a different MRN
                option.isDisabled = true
                disabledCount++
            }
        }
    })

    return {
        options,
        disabledCount
    }
}

const getOptions = (
    trialSites: TrialResponse['trialSites'],
    isIdentifiedPatientLevelUpload: boolean,
    identifiedMrn: string,
    sitePatients?: SitePatientsForPatientResponse['sitePatients'],
): { options: Option[], disabledCount: number } => {
    if (sitePatients) {
        return getMrnLabelledOptions(trialSites, isIdentifiedPatientLevelUpload, identifiedMrn, sitePatients)
    }

    return {
        options: trialSitesToOptions(trialSites),
        disabledCount: 0
    }
}

function IdentifiedPatientLevelUploadDisabledMessage ({
    identifiedMrn
}: {
    identifiedMrn: string
}) {
    const message = `The studies being uploaded have an MRN of ${identifiedMrn}, yet all scan sites for this patient have different MRNs. You are likely uploading to the wrong patient.`

    return (
        <div>
            <p className="site-selector-warning">
                {message}
            </p>
        </div>
    )
};

function SiteSelector({
    selectedSiteId,
    trialSites,
    sitePatients,
    isIdentifiedPatientLevelUpload,
    identifiedMrn,
    onChange
}: SiteSelectorProps) {
    const { options, disabledCount } = getOptions(trialSites, isIdentifiedPatientLevelUpload, identifiedMrn, sitePatients)

    let selectedOption: Option | undefined = undefined

    if (selectedSiteId) {
        selectedOption = options.find(op => op.value === String(selectedSiteId))
    }

    const onSelectChange = (value: string) => {
        onChange(Number(value))
    }

    return (
        <div className='site-selector-container'>
            <div className='site-selector-select-container'>
                <h4 className='site-selector-label'>Scan Site</h4>
                <div className='site-selector-input'>
                    <StatelessSelectInput 
                        placeholder='Select a scan site'
                        options={options}
                        selectedOption={selectedOption}
                        onChange={onSelectChange}
                    />
                </div>
            </div>
            {isIdentifiedPatientLevelUpload && disabledCount === options.length ?
            <IdentifiedPatientLevelUploadDisabledMessage
                identifiedMrn={identifiedMrn}
            /> : null}
        </div>
    )
}

export default SiteSelector