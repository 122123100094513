import React from "react";
// import { useFileState } from "../../providers/FileStateProvider";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DICOMFormatPatientInfo } from "../../lib/patientInformation/getDICOMFormattedPatientInfo";
import "./PatientInfoInput.css"
import PatientInfoRow from './PatientInfoRow'


type PatientInfoArgs = {
  title: string;
  defaultPatientInfo?: DICOMFormatPatientInfo,
  setNewValue: (keyword: "PatientID" | "PatientName" | "PatientBirthDate" | "PatientSex", value: string) => void
}

const PatientInfoInput = ({
  title,
  defaultPatientInfo,
  setNewValue
}: PatientInfoArgs) => {
  
    /**
     * Note: the LocalizationProvider is required to drive any date picker components in the table hierarchy.
     */
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
          <h2 className='patient-info-table-header'>{title}</h2>
          <table className='patient-info-table'>
            <tbody>
              <PatientInfoRow
                displayName="Medical Record Number"
                dicomKeyword={"PatientID"}
                setNewValue={(value: any) => setNewValue("PatientID", value)}
                defaultValue={defaultPatientInfo?.PatientID.value || ''}
                fixed={defaultPatientInfo?.PatientID.locked || false}
              />
              <PatientInfoRow
                displayName="Patient Name"
                dicomKeyword={"PatientName"}
                setNewValue={(value: any) => setNewValue("PatientName", value)}
                defaultValue={defaultPatientInfo?.PatientName.value || ''}
                fixed={defaultPatientInfo?.PatientName.locked || false}
              />
              <PatientInfoRow
                displayName="Date Of Birth"
                dicomKeyword={"PatientBirthDate"}
                setNewValue={(value: any) => setNewValue("PatientBirthDate", value)}
                defaultValue={defaultPatientInfo?.PatientBirthDate.value || ''}
                fixed={defaultPatientInfo?.PatientBirthDate.locked || false}
              />
              <PatientInfoRow
                displayName="Gender"
                dicomKeyword={"PatientSex"}
                setNewValue={(value: any) => setNewValue("PatientSex", value)}
                defaultValue={defaultPatientInfo?.PatientSex.value || ''}
                fixed={defaultPatientInfo?.PatientSex.locked || false}
              />
            </tbody>
          </table>
        </div>
    
        </LocalizationProvider>
      );
}


export default PatientInfoInput